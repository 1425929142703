<template>
  
  <div class="d-flex flex-column flex-root">

    <div class="row d-none" style="flex: none !important;">
      <div class="col col-md-12" style="background-color: #daecff;">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-14">
          <a href="https://www.segeplan.gob.gt" target="_blank" class="text-center mb-10">
            <img
              src="media/logos/logo-letter-1.png"
              class="max-h-120px logo-sege"
              alt=""
            />
          </a>
          <p
            class="font-weight-bold text-center font-size-h5 font-size-h5-lg ml-8 mr-8 mb-14"
            style="color: #063b5c;"
          >
            SISTEMA DE GESTIÓN, EJECUCIÓN Y ANÁLISIS DE INFORMACIÓN DEL SISTEMA
            NACIONAL DE COOPERACIÓN PARA EL DESARROLLO
          </p>
        </div>
      </div>
    </div>

    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
      style="background-size: 100%; background-image: url(media/bg/login-bg.jpg);  background-size:     cover; "
    >

    
      <!--begin::Aside-->
      <!-- <div
        class="login-aside d-flex flex-column flex-row-auto d-none"
        style="background-color: #dbe3ff;"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/sigeaci_logo.png"
              class="max-h-70px"
              alt=""
            />
          </a>
          <p
            class="font-weight-bold text-justify font-size-h5 font-size-h5-lg ml-8 mr-8"
            style="color: #063b5c;"
          >
            SISTEMA DE GESTIÓN, EJECUCIÓN Y ANÁLISIS DE INFORMACIÓN DEL SISTEMA
            NACIONAL DE COOPERACIÓN PARA EL DESARROLLO
          </p>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div> -->
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto" style="max-width: 700px;"
      >
      
        <div class="d-flex flex-column-fluid flex-center">

          
          

          
          <!--begin::Signin-->
          <div class="login-form login-signin" style="background-color:#fff; max-width: 700px; padding: 40px; border-radius: 8px;" :loading="loading">
           <v-container fluid style="padding: 0px; width: calc(100% + 106px); max-width:1000px; margin-left: -53px !important; margin-right: -53px !important; ">
            <DivisorColor :ptl="`8px`" :ptr="`8px`" :pbl="`0px`" :pbr="`0px`" style="margin-top: -45px; "/>

          </v-container>
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="row pt-4" style="flex: none !important;">
                <div class="col col-md-12">
                  <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-4">
                    <a href="https://www.segeplan.gob.gt" target="_blank" class="text-center mb-10">
                      <img
                        src="media/logos/logo-letter-1.png"
                        class="max-h-120px logo-sege"
                        alt=""
                      />
                    </a>
                    <!-- <p
                    class="font-weight-bold text-center font-size-h6 font-size-h5-lg ml-8 mr-8 mb-14"
                    style="color: #063b5c;"
                  >
                    SISTEMA DE GESTIÓN, EJECUCIÓN Y ANÁLISIS DE INFORMACIÓN DEL SISTEMA
                    NACIONAL DE COOPERACIÓN PARA EL DESARROLLO
                  </p> -->
                  </div>
                </div>
              </div>
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Iniciar sesión
                </h3>
                <span class="text-muted font-weight-bold font-size-h4 d-none"
                  >New Here?
                  <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    @click="showForm('signup')"
                    >Create an Account</a
                  ></span
                >
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Usuario</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Contraseña</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >¿Olvidó su contraseña?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  :disabled="btnLoginDisabled"
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Iniciar Sesión
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Sign Up
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your details to create your account
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Fullname"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Confirm password"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  I Agree the
                  <a href="#" class="ml-2">terms and conditions</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width:150px;"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot" style="background-color:#fff; max-width: 700px; padding: 40px; border-radius: 8px;">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
              
             
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  ¿Olvidó su contraseña?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Ingrese su correo electrónico para reestablecer tu contraseña
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  dense
                  filled
                  placeholder="Correo electrónico"
                  name="email"
                  v-model="resetPassUser"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="submit"
                  id="kt_login_forgot_submit"
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  :disabled="btnForgotLoading"
                  @click="resetPasswordEmail"
                  :loading="btnForgotLoading"
                >
                  Enviar
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  :disabled="btnForgotLoading"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"

                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
        <router-link
          :to="`/`"
          v-slot="{ href }"
        >
          <a :href="href" class="text-primary font-weight-bolder font-size-h5"
            >Regresar al inicio</a
          >
        </router-link>

          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Términos de uso</a
          >
          <!--<a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Planes</a>-->
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Contacto</a
          >

          <router-link
            :to="`/solicitudes/usuarios-proyecto`"
            v-slot="{ href }"
          >

          <a :href="href" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Solicitud de usuarios</a>

            </router-link>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
    <!--Inicio:: Snack alert-->
        <SnackAlert ref="snackalert"></SnackAlert>
        <!-- Fin:: Snack alert-->

  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";


import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";

import { ACTUALIZAR_PASS_USUARIO_EMAIL} from "@/core/services/store/usuarios/usuario.module";
import validations from "@/core/untils/validations.js";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  components: {
    SnackAlert,
    DivisorColor
  },
  data() {
    return {
      state: "signin",
      validFormForgot: false,
      // Remove this dummy login info
      btnLoginDisabled: false,
      btnForgotLoading: false,
      resetPassUser: "",
      form: {
        email: "", //admin@demo.com
        password: "" //demo
      },
      loading: true,
      ...validations
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "El usuario es obligatorio"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "La contraseña es obligatoria"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "The value is not a valid email address"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "El usuario es obligatorio"
            },
            emailAddress: {
              message: "Debe ingresar un usuario válido"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2.on("core.form.valid", () => {
      this.btnForgotLoading=true;
    });

    this.fv.on("core.form.valid", () => {
      this.btnLoginDisabled=true;
      var username = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
     // setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { username, password })
          // go to which page after successfully login
          .then(data => {
           //console.log(data)
            if(data.status===200){

              let roles = data.data.roles.map(a => a.nombreRol);

             ///  console.log(roles) 
              if(roles.includes("SCO$ADMINISTRADOR") || roles.includes("SCO$SUBADMINISTRADOR")){
                this.$router.push({ name: "dashboard-administrador" });
              } 
              else if(roles.includes("SCO$SEGUIMIENTOCNS") || roles.includes("SCO$SEGUIMIENTOCSS") || roles.includes("SCO$SEGUIMIENTOALIANZAS") || roles.includes("SCO$SEGUIMIENTOCNSC") || roles.includes("SCO$SEGUIMIENTOACTLOCALES") || roles.includes("SCO$SEGUIMIENTOCOODESC")){
                this.$router.push({ name: "dashboard-seguimiento" });
              }
              else if(roles.includes("SCO$ADMINISTRADOR") || roles.includes("SCO$SUBADMINISTRADOR")){
                this.$router.push({ name: "dashboard-administrador" });
              } 
              else if(data.data.roles.includes(601)){
                this.$router.push({ name: "dashboard-utc" });
              }  else if(roles.includes("SCO$INSTCNS") || roles.includes("SCO$INSTCSSC") || roles.includes("SCO$INSTCSSD") || roles.includes("SCO$INSTALIANZAS")){
                //console.log(roles) 
                this.$router.push({ name: "dashboard-institucion" });
              } 

              else if(roles.includes("SCO$COOPERANTEBI") || roles.includes("SCO$COOPERANTEMULTI")){
                this.$router.push({ name: "dashboard-cooperante" });
              }

              else if(roles.includes("SCO$UDAF")){
                this.$router.push({ name: "dashboard-udaf" });
              }

              else if(roles.includes("SCO$INSALIANZAS")){
                this.$router.push({ name: "dashboard-alianzas" });
              }
              
              else if(data.data.roles.includes(401)){
                this.$router.push({ name: "dashboard-sur-sur" });
              } else{
                this.$router.push({ name: "dashboard" });
              }
            } else {
              this.$refs.snackalert.SnackbarShow("warning", "Alerta", data.message);
            }

            






            this.btnLoginDisabled=false;
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            this.btnLoginDisabled=false;
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });

        
    //  }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        position: 'top-end',
        title: "",
        text: "Por favor, ingrese la información necesaria!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });

    this.fv1.on("core.form.valid", () => {
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(REGISTER, {
            email: email,
            password: password
          })
          .then(() => this.$router.push({ name: "dashboard" }));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv1.on("core.form.invalid", () => {
      Swal.fire({
        position: 'top-end',
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },


    async resetPasswordEmail(){
      const forgotButton = this.$refs["kt_login_forgot_submit"];
      forgotButton.classList.add("spinner", "spinner-light", "spinner-right");

      let reg = /.+@.+\..+/;
      


      this.btnForgotLoading=true;
      if(this.resetPassUser.length <= 3){
          Swal.fire({
          position: 'top-end',
          title: "",
          text: "El nombre de usuario o correo son inválidos",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });

        this.btnForgotLoading=false;
        return;
      }
      
      
      if(!reg.test(this.resetPassUser)){
        Swal.fire({
          position: 'top-end',
          title: "",
          text: "No se ha ingresado un correo electrónico válido",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });

        this.btnForgotLoading=false;
        return;
      }

      await this.$store
          .dispatch(ACTUALIZAR_PASS_USUARIO_EMAIL, { usuario: this.resetPassUser })
          // go to which page after successfully login
          .then(data => {
           //console.log(data)
            if(data.status===200){

                 Swal.fire({
                  position: 'top-end',
                  title: "",
                  text: data.message,
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                  heightAuto: false
                });

                this.resetPassUser = "";

                this.showForm('signin');
              
            } else {

               Swal.fire({
                position: 'top-end',
                title: "Error",
                text: data.data,
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false
              });
             
            }

            forgotButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );

            this.btnForgotLoading=false;
           /*  submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            ); */
          })
          .catch(() => {
            //console.log(error)
            forgotButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            this.btnForgotLoading=false;
             Swal.fire({
              title: "",
              text: "Ha ocurrido un error al intentar el reinicio de contraseña. Si el problema persiste, por favor, póngase en contacto con un administrador.",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false
            });
            /* submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            ); */
          });
    }
  }
};
</script>


<style lang="scss">
  
  @media only screen and (max-width: 480px)  {

    .logo-sege{
      max-height: 65px !important;
    }

  }

</style>